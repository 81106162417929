<template>
  <div>
    <div
      style="background-color: rgba(50, 61, 76, 1)"
      class="invoice_header text-white invoice-padding m-0 p-0"
    >
      <!-- Header -->
      <b-card-body class="invoice-padding mx-1 py-4">
        <div class="d-flex justify-content-between invoice-spacing mt-0 mr-1">
          <!-- Header: Left Content -->
          <div>
            <div class="logo-wrapper">
              <logo />
            </div>
            <p class="card-text mb-25 mt-2 base-paragraph">Clearspace Offices Inc.</p>
            <p class="card-text mb-25 base-paragraph">
              {{
                quote.geography != "Montréal" ? "901-20 Victoria Street" : montrealAddress
              }}
            </p>
            <p class="card-text mb-0 base-paragraph">
              {{
                quote.geography != "Montréal"
                  ? "Toronto, ON, Canada M5C 2N8"
                  : "Montréal, QC, Canada H3B 2N2"
              }}
            </p>
            <p class="card-text mb-0 base-paragraph" v-if="quote.geography == 'Montréal'">
              {{ $t("quote.quebecEnterpriseNumber", "en") }}
            </p>
            <p class="card-text mb-0 base-paragraph" v-if="quote.geography == 'Montréal'">
              {{ $t("quote.RbqLicense", "en") }}
            </p>
          </div>

          <!-- Header: Right Content -->
          <div class="mt-md-0 mt-2 mx-0">
            <h4 class="invoice-numbercs mb-2 text-white" style="text-align: end">
              Estimate #{{ quote.tfr.tfr }}
            </h4>
            <div class="invoice-date-wrapper" style="width: 250px">
              <div class="row">
                <div class="col-6">
                  <p
                    class="invoice-date-title my-0 py-0"
                    style="padding-bottom: 10px !important"
                  >
                    {{ $t("quote.issueDate", "en") }}
                  </p>
                </div>
                <div class="col-6">
                  <p class="invoice-date text-right">
                    {{ quote.issue_date }}
                  </p>
                </div>
              </div>
            </div>
            <div class="invoice-date-wrapper" style="width: 250px">
              <div class="row">
                <div class="col-6">
                  <p class="invoice-date-title">{{ $t("quote.expiryDate", "en") }}:</p>
                </div>
                <div class="col-6">
                  <p class="invoice-date text-right">
                    {{ new Date(quote.expiration_date).toLocaleDateString("en-CA") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
    </div>
    <!-- Invoice Client & Payment Details -->
    <b-card-body class="invoice-padding mt-3 pt-0">
      <b-row class="invoice-spacing" style="margin-left: 29px">
        <!-- Col: Invoice To -->
        <b-col class="pl-0" cols="8">
          <h6 class="header-label">
            {{ $t("quote.address", "en") }}
          </h6>
          <h2
            class="card-text mb-25"
            style="
              font-weight: 500 !important;
              font-size: 28px !important;
              line-height: 26px !important;
              color: #636363 !important;
              text-transform: uppercase;
            "
          >
            {{ quote.address }}
          </h2>
          <h2
            class="card-text mb-25"
            style="
              font-weight: 500 !important;
              font-size: 28px !important;
              line-height: 26px !important;
              color: #636363 !important;
              text-transform: uppercase;
            "
          >
            {{ quote.geography }}
            <!-- {{quote.project.city + ', ' + quote.project.state + ', Canada ' + quote.project.postcode}} -->
          </h2>
          <h6 class="mt-2 header-label">Site SQFT</h6>
          <p class="card-text mb-3">
            {{
              Number(quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 0,
              }) +
              " " +
              $t("quote.sqft", "en")
            }}
          </p>
        </b-col>
        <!-- <b-col
                  class=""
                  cols="4"
                >
                  <h6 class="mb-1 header-label">
                    {{$t('quote.billTo', quote.language)}}
                  </h6>
                  <p class="card-text mb-25">
                    {{ quote.opportunity.account_name }}
                  </p>
                  <p class="card-text mb-25">
                    {{ quote.opportunity.contact_name }}
                  </p>
                  <p class="card-text mb-25">
                    {{ quote.opportunity.contact_phone }}
                  </p>
                  <p class="card-text mb-0">
                    {{ quote.opportunity.contact_email }}
                  </p>
                </b-col> -->

        <!-- Col: Payment Details -->
        <b-col class="mt-xl-0 mt-2 d-flex justify-content-xl-end" cols="4"> </b-col>
      </b-row>

      <template v-for="group in groups">
        <div v-if="quote.options.filter((o)=>o.budget_group===group.key).length > 0">
          <b-table-simple
              caption-top
              responsive
              class="mt-2 my-2 invoice-padding"
              style="padding-right: 25px; padding-left: 28px; overflow-y: hidden; border: 0px"
              :key="group.key"
          >
            <b-thead>
              <b-tr style="border: 0px">
                <b-th class="table-background-color" colspan="8"> {{group.label}} </b-th>
                <b-th class="table-background-color text-right" colspan="2"> Subtotal </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(option, index) in quote.options.filter((o)=>o.budget_group===group.key)" :key="index + 'sc'">
                <b-td colspan="8">
                  <b>{{ option.name }}</b>
                  <p>{{ option.details }}</p>
                </b-td>
                <b-td class="text-right" colspan="2">{{
                    Number(option.total_price).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot>
              <!--  v-if="
                              Number(Math.abs(quote.site_conditions_price_per_sqft)) <= 0 &&
                              Number(Math.abs(quote.options_price_per_sqft)) <= 0
                            " -->
              <b-tr class="border-footer border-footer-light">
                <b-td
                    class="row-label project-total-label"
                    colspan="8"
                    style="border-top: none font-size: 15px; font-weight: 600"
                >
                  Subtotal
                </b-td>
                <b-td
                    colspan="2"
                    class="row-label text-right"
                    style="font-size: 15px; font-weight: 600"
                >
                  <!-- Number(estimateContractTotal()).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  }) -->
                  {{
                    Number(groupTotalPrice(group.key)).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </div>
      </template>
<!--
      <b-table-simple
        caption-top
        responsive
        v-if="items.length > 0"
        style="padding-right: 28px; padding-left: 28px; overflow-y: hidden; border: 0px"
      >
        <b-thead>
          <b-tr style="border: 0px">
            <b-th class="table-background-color" colspan="8"> Items </b-th>
            <b-th class="table-background-color text-right" colspan="2"> Subtotal </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(i, index) in items" :key="index + 'ax'">
            <b-td colspan="8">
              <b>{{ i.name }}</b>
              <p>{{ i.details }}</p>
            </b-td>
            <b-td class="text-right" colspan="2">{{
              Number(i.total_price).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-table-simple
          v-if="items.length > 0"
          caption-top
          responsive
          style="padding-right: 28px; padding-left: 28px; overflow-y: hidden; border: 0px"
      >
        <b-tfoot style="border-bottom: 0px">
          &lt;!&ndash;  v-if="
                          Number(Math.abs(quote.site_conditions_price_per_sqft)) <= 0 &&
                          Number(Math.abs(quote.options_price_per_sqft)) <= 0
                        " &ndash;&gt;
          <b-tr class="border-footer-light">
            <b-td
                class="row-label project-total-label"
                colspan="8"
                style="border-top: none font-size: 15px; font-weight: 600"
            >
              Subtotal
            </b-td>
            <b-td
                colspan="2"
                class="row-label text-right"
                style="font-size: 15px; font-weight: 600"
            >
              &lt;!&ndash; Number(estimateContractTotal()).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              }) &ndash;&gt;
              {{
                Number(itemsSubtotal).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
      <b-table-simple
        v-if="scItems.length > 0"
        caption-top
        responsive
        class="mt-2"
        style="padding-right: 25px; padding-left: 28px; overflow-y: hidden; border: 0px"
      >
        <b-thead>
          <b-tr style="border: 0px">
            <b-th class="table-background-color" colspan="8"> SOFT COSTS </b-th>
            <b-th class="table-background-color text-right" colspan="2"> Subtotal </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(sc, index) in scItems" :key="index + 'sc'">
            <b-td colspan="8">
              <b>{{ sc.name }}</b>
              <p>{{ sc.details }}</p>
            </b-td>
            <b-td class="text-right" colspan="2">{{
              Number(sc.total_price).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-table-simple
        v-if="scItems.length > 0"
        caption-top
        responsive
        style="padding-right: 25px; padding-left: 28px; overflow-y: hidden; border: 0px"
      >
        <b-tfoot>
          &lt;!&ndash;  v-if="
                          Number(Math.abs(quote.site_conditions_price_per_sqft)) <= 0 &&
                          Number(Math.abs(quote.options_price_per_sqft)) <= 0
                        " &ndash;&gt;
          <b-tr class="border-footer border-footer-light">
            <b-td
              class="row-label project-total-label"
              colspan="8"
              style="border-top: none font-size: 15px; font-weight: 600"
            >
              Subtotal
            </b-td>
            <b-td
              colspan="2"
              class="row-label text-right"
              style="font-size: 15px; font-weight: 600"
            >
              &lt;!&ndash; Number(estimateContractTotal()).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              }) &ndash;&gt;
              {{
                Number(scSubtotal).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>-->
      <b-table-simple>
        <b-tfoot>
          <!--  v-if="
                          Number(Math.abs(quote.site_conditions_price_per_sqft)) <= 0 &&
                          Number(Math.abs(quote.options_price_per_sqft)) <= 0
                        " -->
          <b-td style="border-top: none">
            <b-td colspan="8" style="border-top: none"></b-td>
            <b-td colspan="2" style="border-top: none"></b-td>
          </b-td>
          <b-tr class="border-footer">
            <b-td
              class="row-label project-total-label"
              colspan="8"
              style="font-size: 18px; font-weight: 500; border-top: none"
            >&nbsp;&nbsp;&nbsp;&nbsp;
              Estimated Project Total
            </b-td>
            <b-td
              colspan="2"
              class="row-label project-total-number text-right"
              style="font-size: 18px; font-weight: 500"
            >
              <!-- Number(estimateContractTotal()).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              }) -->
              {{
                Number(globalSubtotal).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-card-body>
    <b-card-body class="invoice-padding py-2 footer-quote my-3">
      <span class="confidential-line float-right">{{
        $t("quote.confidential", "en")
      }}</span>
      <div class="page_botton_margin"></div>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCardBody,
  BCol,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
export default {
  props: {
    quote: {
      type: Object,
      default: [],
    },
    montrealAddress: {
      type: String,
      default: [],
    },
    siteConditionsConstructionSubtotal: {
      type: Number,
      default: 0,
    },

    optionsConstructionSubtotal: {
      type: Number,
      default: 0,
    },
    optionsFfeSubtotal: {
      type: Number,
      default: 0,
    },
    optionsAvItSubtotal: {
      type: Number,
      default: 0,
    },
    showOptions: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    BCardBody,
    BCol,
    BRow,
    BTableSimple,
    BTbody,
    BTd,
    BTfoot,
    BTh,
    BThead,
    BTr,
    Logo,
  },
  computed: {
    itemsSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group != "Soft Cost")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    items() {
      let result = this.quote.options.filter((c) => c.budget_group != "Soft Cost");
      return result;
    },
    // Soft cost funcs
    scSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    scItems() {
      let result = this.quote.options.filter((c) => c.budget_group == "Soft Cost");
      return result;
    },

    globalSubtotal() {
      let result = this.quote.options.reduce(
        (total, obj) => Number(obj.total_price) + total,
        0
      );
      return result;
    },
  },
  data() {
    return {
      groups: [
        {
          label: "Hard Construction",
          key: "Construction",
          is_collapsed:true
        },
        {
          label: "Furniture",
          key: "FF&E",
          is_collapsed:true
        },
        {
          label: "AV/IT",
          key: "AV/IT",
          is_collapsed:true
        },
        {
          label: "Soft Costs",
          key: "Soft Cost",
          is_collapsed:true
        },
      ],
    }
  },
  methods: {
    groupTotalPrice(group){
      return this.quote.options.filter((c) => c.budget_group === group).reduce(
          (total, obj) => Number(obj.total_price) + total,
          0
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.border-footer-light {
  border-top: 2px solid #d8d6de;
}
.print:last-child {
  page-break-after: auto;
}
</style>
